import filteredInstruments from "./filtered_instruments.json";

export const INSTRUMENTS = filteredInstruments;

export const LABELS = {
    "rsi": "Relative Strength Index",
    "emaShort": "Exponential Moving Average (Short)",
    "emaLong": "Exponential Moving Average (Long)",
    "macd": "Moving Average Convergence Divergence",
    "smaShort": "Simple Moving Average (Short)",
    "smaLong": "Simple Moving Average (Long)",
    "buySignalCount": "Number of Buy Signals",
    "sellSignalCount": "Number of Sell Signals",
    "supportLevel": "Support Level",
    "resistanceLevel": "Resistance Level",
    "finalSignal": "Final Signal",
    "trend": "Market Trend",
    "intreval": "Time Interval",
    "start": "Start Time",
    "end": "End Time",
    "candleCount": "Number of Candles"
};

export const TABLE_META = {
    intreval: {
        "key": "intreval",
        "title": "Candle Type",
        "dataIndex": "intreval",
        "align": "center",
        "type": "string"
    },
    rsi: {
        "key": "rsi",
        "title": "Relative Strength Index",
        "dataIndex": "rsi",
        "align": "center",
        "type": "number"
    },
    emaShort: {
        "key": "emaShort",
        "title": "Exponential Moving Average (Short)",
        "dataIndex": "emaShort",
        "align": "center",
        "type": "number"
    },
    emaLong: {
        "key": "emaLong",
        "title": "Exponential Moving Average (Long)",
        "dataIndex": "emaLong",
        "align": "center",
        "type": "number"
    },
    macd: {
        "key": "macd",
        "title": "Moving Average Convergence Divergence",
        "dataIndex": "macd",
        "align": "center",
        "type": "number"
    },
    smaShort: {
        "key": "smaShort",
        "title": "Simple Moving Average (Short)",
        "dataIndex": "smaShort",
        "align": "center",
        "type": "number"
    },
    smaLong: {
        "key": "smaLong",
        "title": "Simple Moving Average (Long)",
        "dataIndex": "smaLong",
        "align": "center",
        "type": "number"
    },
    buySignalCount: {
        "key": "buySignalCount",
        "title": "Number of Buy Signals",
        "dataIndex": "buySignalCount",
        "align": "center",
        "type": "number"
    },
    sellSignalCount: {
        "key": "sellSignalCount",
        "title": "Number of Sell Signals",
        "dataIndex": "sellSignalCount",
        "align": "center",
        "type": "number"
    },
    supportLevel: {
        "key": "supportLevel",
        "title": "Support Level",
        "dataIndex": "supportLevel",
        "align": "center",
        "type": "number"
    },
    resistanceLevel: {
        "key": "resistanceLevel",
        "title": "Resistance Level",
        "dataIndex": "resistanceLevel",
        "align": "center",
        "type": "number"
    },
    finalSignal: {
        "key": "finalSignal",
        "title": "Final Signal",
        "dataIndex": "finalSignal",
        "align": "center",
        "type": "string"
    },
    trend: {
        "key": "trend",
        "title": "Market Trend",
        "dataIndex": "trend",
        "align": "center",
        "type": "string"
    },
    start: {
        "key": "start",
        "title": "Candle Start Date & Time",
        "dataIndex": "start",
        "align": "center",
        "type": "string"
    },
    end: {
        "key": "end",
        "title": "Candle End Date & Time",
        "dataIndex": "end",
        "align": "center",
        "type": "string"
    },
    candleCount: {
        "key": "candleCount",
        "title": "Number of Candles",
        "dataIndex": "candleCount",
        "align": "center",
        "type": "number"
    }

};
