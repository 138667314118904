import React, { useState, useCallback } from "react";
import { AutoComplete } from "antd";
import { INSTRUMENTS as instruments } from "../constants";

const InstrumentSelector = ({ onSelect, selectedInstrument }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = useCallback((value) => setSearchText(value), []);
  const filteredInstruments = instruments.filter((instrument) =>
    instrument.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <AutoComplete
      style={{ width: "100%" }}
      placeholder="Select an Instrument"
      onSelect={onSelect}
      onSearch={handleSearch}
      value={searchText}
    >
      {filteredInstruments.map((instrument) => (
        <AutoComplete.Option key={instrument.instrument_key} value={instrument.instrument_key}>
          {instrument.name}
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};

export default InstrumentSelector;
