import React from "react";
import { Table, Typography } from "antd";
import { UpOutlined, DownOutlined, CheckCircleOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { TABLE_META } from "../constants";

const { Title } = Typography;

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const AnalysisTable = ({ data }) => {
  if (!data || data.length === 0) return null;

  if(data[0].intreval==='30minute' && data[1].intreval==='30minute'){
        data[1].intreval='30minute (long)'
  }
  const timeIntervals = [...new Set(data.map((item) => item.intreval))];

  console.log(timeIntervals)
  const columns = [
    {
      title: 'Metrics',
      dataIndex: 'metrics',
      key: 'metrics',
      align: 'center',
      width: 200,
    },
    ...timeIntervals.map((interval) => ({
      title: `${interval} Chart`,
      dataIndex: interval,
      key: interval,
      align: 'center',
      render: (value) => {
        if (typeof value === 'number') {
          return value.toFixed(2);
        }
        return value;
      },
      width: 150, 
    })),
  ];

  const renderMarketTrend = (trend) => {
    if (trend.toLowerCase().includes('downtrend')) {
      return <span style={{ color: 'red' }}><WarningOutlined /> {trend}</span>;
    } else if (trend.toLowerCase().includes('uptrend')) {
      return <span style={{ color: 'green' }}><CheckCircleOutlined /> {trend}</span>;
    } else {
      return <span style={{ color: 'gray' }}><InfoCircleOutlined /> {trend}</span>;
    }
  };

  const renderFinalSignal = (trend) => {
    if (trend.toLowerCase().includes('Buy')) {
      return <span style={{ color: 'red' }}><WarningOutlined /> {trend}</span>;
    } else if (trend.toLowerCase().includes('Sell')) {
      return <span style={{ color: 'green' }}><CheckCircleOutlined /> {trend}</span>;
    } else {
      return <span style={{ color: 'gray' }}><InfoCircleOutlined /> {trend}</span>;
    }
  };

  const renderSignalCount = (count, type) => {
    const isBuySignal = type === 'buySignalCount';
    const icon = isBuySignal ? <UpOutlined /> : <DownOutlined />;
    const color = isBuySignal ? 'green' : 'red';
    const label = isBuySignal ? 'Buy Signals' : 'Sell Signals';

    return (
      <span style={{ color }}>
        {icon} {label}: {count}
      </span>
    );
  };

  const transposedData = [
    { metrics: TABLE_META.intreval.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.intreval])) },
    { metrics: TABLE_META.trend.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, renderMarketTrend(data.find(item => item.intreval === interval)?.trend)])) },
    { metrics: TABLE_META.finalSignal.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, renderFinalSignal(data.find(item => item.intreval === interval)?.finalSignal)])) },
    { metrics: TABLE_META.buySignalCount.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, renderSignalCount(data.find(item => item.intreval === interval)?.buySignalCount, 'buySignalCount')])) },
    { metrics: TABLE_META.sellSignalCount.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, renderSignalCount(data.find(item => item.intreval === interval)?.sellSignalCount, 'sellSignalCount')])) },
    { metrics: TABLE_META.supportLevel.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.supportLevel])) },
    { metrics: TABLE_META.resistanceLevel.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.resistanceLevel])) },

    { metrics: TABLE_META.rsi.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.rsi])) },
    { metrics: TABLE_META.emaShort.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.emaShort])) },
    { metrics: TABLE_META.emaLong.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.emaLong])) },
    { metrics: TABLE_META.macd.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.macd])) },
    { metrics: TABLE_META.smaShort.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.smaShort])) },
    { metrics: TABLE_META.smaLong.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.smaLong])) },
    { metrics: TABLE_META.start.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, formatDate(data.find(item => item.intreval === interval)?.start)])) },
    { metrics: TABLE_META.end.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, formatDate(data.find(item => item.intreval === interval)?.end)])) },
    { metrics: TABLE_META.candleCount.title, ...Object.fromEntries(timeIntervals.map((interval) => [interval, data.find(item => item.intreval === interval)?.candleCount])) },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <Title level={4}>Analysis Results</Title>
      <Table
        columns={columns}
        dataSource={transposedData}
        pagination={false}
        bordered
        rowKey={(record) => record.metrics}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default AnalysisTable;
