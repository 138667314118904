import React from "react";
import { DatePicker } from "antd";

const DateSelector = ({ onChange }) => (
  <DatePicker
    style={{ width: "100%" }}
    onChange={onChange}
    />
);

export default DateSelector;
