import React, { useCallback, useState } from "react";
import { Typography, Button, message, Modal, Spin, Row, Col } from "antd";
import axios from "axios";
import { INSTRUMENTS as instruments } from "./constants";
import InstrumentSelector from "./components/InstrumentSelector";
import TradeTypeSelector from "./components/TradeTypeSelector";
import DateSelector from "./components/DateSelector";
import moment from "moment";
import AnalysisTable from "./components/Table";
import { SignalFilled } from "@ant-design/icons";

const { Title } = Typography;

const App = () => {
  const [selectedInstrument, setSelectedInstrument] = useState("");
  const [selectedTradeType, setSelectedTradeType] = useState("Day");
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [analysisData, setAnalysisData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [chartImageUrl, setChartImageUrl] = useState(null); // State for chart image URL

  const handleInstrumentSelect = (value) => setSelectedInstrument(value);
  const handleTradeTypeChange = (value) => setSelectedTradeType(value);
  const handleDateChange = (date, dateString) => {
    console.log(dateString);
    setSelectedDate(dateString)};

  const handleAnalyzeClick = async () => {
    if (!selectedInstrument || !selectedTradeType) {
      message.error("Please select both instrument and trade type.");
      return;
    }

    const date = selectedDate || moment().format("YYYY-MM-DD");
    const requestPayload = {
      instrumentKey: selectedInstrument,
      toDate: date,
    };
    const strategy = selectedTradeType.toLowerCase();

    try {
      const response = await axios.post(
        `https://api.bizbrains.co.in/api/v1/analyse?strategy=${strategy}`,
        requestPayload,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );
      message.success("Analysis complete!");
      setAnalysisData(response.data); // Set the analysis data to state
    } catch (error) {
      message.error("Failed to fetch analysis");
      console.error(error);
    }
  };




  const fetchChartData = useCallback(async () => {
    if (!selectedInstrument) {
      message.error("Please select the instrument.");
      return;
    }

    setIsLoading(true); // Show the loading spinner
    try {
      // First API call to get data (equivalent to the first `curl` you provided)
      const dataResponse = await axios.post(
        "https://api.bizbrains.co.in/api/v1/data",
        {
          instrumentKey: selectedInstrument,
          interval: "1minute",
          fromDate: moment().format("YYYY-MM-DD"),
          toDate: moment().format("YYYY-MM-DD")
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Second API call to generate chart image (equivalent to the second `curl` you provided)
      const chartResponse = await axios.post(
        "https://api.bizbrains.co.in/api/v1/generateChart",
        dataResponse.data,
        {
          headers: { "Content-Type": "application/json" },
          responseType: "arraybuffer", // Ensures the response is treated as binary data
        }
      );

      // Convert the binary data to base64 using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result; // This will contain the Base64 encoded image data
        setChartImageUrl(base64Image); // Store the chart image URL in state
        setIsModalVisible(true); // Open the modal
      };

      // Convert the binary data to a Blob and read it as a data URL
      const blob = new Blob([chartResponse.data], { type: "image/png" });
      reader.readAsDataURL(blob);
      // Set the generated chart image URL (Assuming the response contains an image URL or base64 image)
      setChartImageUrl(reader); // Replace with the actual response format
      setIsLoading(false); // Hide the loading spinner
    } catch (error) {
      console.error("Error fetching chart data:", error);
      message.error("Failed to fetch chart data.");
      setIsLoading(false);
    }
  }, [selectedInstrument]);

  // Function to show the modal
  const showGenerateModal = () => {
    setIsModalVisible(true);
    fetchChartData();
  };
  // Function to close the modal
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <Title level={3}>Instrument Selector</Title>
      {instruments.length > 0 ? (
        <>
          {/* Instrument Selector */}
          <InstrumentSelector onSelect={handleInstrumentSelect} selectedInstrument={selectedInstrument} />

          {selectedInstrument && (
            <div style={{ marginTop: "20px" }}>
              <Typography.Text strong>Selected Instrument Key:</Typography.Text>{" "}
              <Typography.Text>{selectedInstrument}</Typography.Text>
            </div>
          )}

          {/* Trade Type Selector */}
          <div style={{ marginTop: "20px" }}>
            <TradeTypeSelector onChange={handleTradeTypeChange} value={selectedTradeType} />
            {selectedTradeType && (
              <div style={{ marginTop: "20px" }}>
                <Typography.Text strong>Selected Trade Type:</Typography.Text>{" "}
                <Typography.Text>{selectedTradeType}</Typography.Text>
              </div>
            )}
          </div>

          {/* Date Selector */}
          <div style={{ marginTop: "20px" }}>
            <DateSelector selectedDate={selectedDate} onChange={handleDateChange} />
            {selectedDate && (
              <div style={{ marginTop: "20px" }}>
                <Typography.Text strong>Selected Date:</Typography.Text>{" "}
                <Typography.Text>{selectedDate}</Typography.Text>
              </div>
            )}
          </div>

          {/* Analyze Button */}
          <div style={{ marginTop: "20px" }}>
            <Row gutter={16}> {/* Add gutter to control the spacing between buttons */}
              <Col span={12}>
                <Button type="primary" onClick={handleAnalyzeClick} block>
                  Analyze
                </Button>
              </Col>
              <Col span={12}>
                <Button type="default" icon={<SignalFilled />} onClick={showGenerateModal} block>
                  Generate Intraday Signal Chart
                </Button>
              </Col>
            </Row>
          </div>

          {analysisData && <AnalysisTable data={analysisData} />}

          {/* Generate Button */}

          {/* Candlestick Chart */}
          {/* <div style={{ marginTop: "20px" }}>
            <h1> Chart Example</h1>
            <CandlestickChart />
          </div> */}

          {/* Modal for Generate button */}

          <Modal
            title="Generated Data"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null} // No footer buttons
            width={1000} // Set width to 80% or adjust as needed
            height={1000}
            loading={isLoading}
            bodyStyle={{ maxHeight: '50vh', overflowY: 'auto' }} // Make the body scrollable and limit its height
          >
            {isLoading ? (
              <Spin size="large" tip="Loading chart..."
                style={{
                  display: 'block',
                  margin: '0 auto',
                  height: '100%', // Ensure the spinner takes up the full modal height
                }} />
            ) : (
              chartImageUrl ? (
                <img src={chartImageUrl} alt="Generated Chart" style={{ width: '100%', height: '50%', display: 'block', maxHeight: 'inherit', margin: '0 auto' }} />
              )
                : (
                  <Typography.Text>No chart available</Typography.Text>
                )
            )}
          </Modal>


        </>
      ) : (
        <Typography.Text>Loading instruments...</Typography.Text>
      )}
      {
        <div style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          background: '#f0f0f0',
          textAlign: 'center',
          padding: '10px 0',
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)'
        }}>
          {/* <a href="https://www.buymeacoffee.com" target="_blank" rel="noopener noreferrer">
            <Button type="link">Buy Me a Coffee</Button>
          </a> */}
          <Typography.Text style={{ display: 'block', marginBottom: '5px' }}>
            For Educational Purpose only
          </Typography.Text>
        </div>
      }





    </div>


  );
};

export default App;
