import React from "react";
import { Select } from "antd";

const { Option } = Select;

const TradeTypeSelector = ({ onChange, value }) => (
  <Select style={{ width: "100%" }} placeholder="Select Trade Type" onChange={onChange} value={value}>
    <Option value="Day">Intraday</Option>
    <Option value="Swing">Swing Trade</Option>
    <Option value="Short">Short Term</Option>
    <Option value="Long">Long Term</Option>
  </Select>
);

export default TradeTypeSelector;
